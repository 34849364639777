import _export from "../internals/export";
import _createHtml from "../internals/create-html";
import _stringHtmlForced from "../internals/string-html-forced";
var $ = _export;
var createHTML = _createHtml;
var forcedStringHTMLMethod = _stringHtmlForced; // `String.prototype.link` method
// https://tc39.es/ecma262/#sec-string.prototype.link

$({
  target: "String",
  proto: true,
  forced: forcedStringHTMLMethod("link")
}, {
  link: function link(url) {
    return createHTML(this, "a", "href", url);
  }
});
export default {};